import { humanize } from 'util/format'
import updateTree from './updateTree'

export const knownSorts = {
  TIME_CREATED: 'time_created_desc',
  EARLIEST_PICKUP: 'pickup_time_desc',
  OLDEST_PICKUP: 'pickup_time_asc',
  DRIVER_NAME: 'driver_name_asc',
}

export function sortParam(knownSort) {
  switch(knownSort) {
    case knownSorts.TIME_CREATED: return { created_at: { order: 'desc' } }
    case knownSorts.EARLIEST_PICKUP: return { pickup_time: { order: 'asc' } }
    case knownSorts.OLDEST_PICKUP: return { pickup_time: { order: 'desc' } }
    case knownSorts.DRIVER_NAME: return { driver_name: { order: 'asc' } }
    default: return null
  }
}

export const knownBuckets = [
  { name: 'scheduled', group: 'Active', default: true },
  { name: 'published', group: 'Active', default: true },
  { name: 'accepted', group: 'Active', default: true, label: 'In Progress' },
  { name: 'delivered' },
  { name: 'delivery_attempted' },
  { name: 'expired' },
  { name: 'cancelled', label: 'Canceled' },
]

knownBuckets.forEach(b => {
  if (!b.label) {
    b.label = humanize(b.name)
  }
  if (!b.group) {
    b.group = 'Archived'
  }
})

export function statusesIsDefault(buckets) {
  let answer = true
  knownBuckets.forEach(bucket => {
    if (bucket.default && !buckets.includes(bucket.name)) {
      answer = false
    } else if (!bucket.default && buckets.includes(bucket.name)) {
      answer = false
    }
  })
  return answer
}

export default function gigSearch(state = {}, action) {
  switch (action.type) {

    case 'GIG_SEARCH_SEND_LOADING': {
      return updateTree(state, 'gigSearch.send.loading', () => action.loading)
    }

    case 'GIG_SEARCH_SEND_UPDATE_Q': {
      return updateTree(state, 'gigSearch.send.criteria', criteria => {
        
        const newCriteria = { ...criteria }
        
        if ('q' in action) {
          newCriteria.q = action.q
        } else {
          delete newCriteria.q
          delete newCriteria.category
        }

        return newCriteria 
      })
    }

    case 'GIG_SEARCH_SEND_UPDATE_FILTERS': {
      return updateTree(state, 'gigSearch.send.criteria.filters', () => action.filters)
    }
    
    case 'GIG_SEARCH_SEND_SET_CATEGORY': {
      return updateTree(state, 'gigSearch.send.criteria.category', send => action.category)
    }

    case 'GIG_SEARCH_SEND_CLEAR_RESULTS': {
      return updateTree(state, 'gigSearch.send', send => ({
        ...send,
        nextPage: 1,
        results: {},
      }))
    }

    case 'GIG_SEARCH_SEND_SET_SORT': {
      return updateTree(state, 'gigSearch.send.sort', () => action.sort)
    }

    case 'GIG_SEARCH_SEND_SET_LENS': {
      return updateTree(state, 'gigSearch.send', send => ({
        ...send,
        lens: action.lens,
        nextPage: 1,
        results: {}, // We must clear results here or the wrong results can get rendered to
                     // the wrong lens type, causing a crash.
      }))
    }

    case 'GIG_SEARCH_SEND_SET_STATUSES': {
      return updateTree(state, 'gigSearch.send', send => ({
        ...send,
        statuses: action.statuses,
      }))
    }

    case 'GIG_SEARCH_SEND_RESULTS': {
      return updateTree(state, 'gigSearch.send', send => ({
        ...send,
        nextPage: action.complete ? null : action.page + 1,
        results: {
          ...send.results,
          [action.page]: action.results
        },
        buckets: knownBuckets.map(bucket => {
          const aggr = action.aggregates.find(a => a[bucket.name])
          const count = (aggr && aggr[bucket.name] && aggr[bucket.name].total) || 0
          return ({ ...bucket, count })
        }),
      }))
    }

    default:
      return {
        gigSearch: {
          send: {
            lens: 'gig',
            sort: knownSorts.TIME_CREATED,
            results: {},
            criteria: {},
            nextPage: 1,
            loading: false,
            buckets: [],
            statuses: knownBuckets.filter(b => b.default).map(b => b.name),
          }
        },
        ...state
      }
  }
}