import updateTree from './updateTree'

export default function notifications(state = {}, action) {
  switch (action.type) {

    case 'SET_NOTIFICATION_SETTINGS': {
      return updateTree(state, 'notificationSettings', () => action.settings)
    }

    default:
      return {
        notificationSettings: {},
        ...state
      }
  }
}