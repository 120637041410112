import colors, { dark } from 'styles/colors'
import { MainTheme as RoadieUIMain, DarkTheme as RoadieUIDark } from 'roadie-ui'

export const roadie = {
  RoadieUI: RoadieUIMain,

  colors,

  headerbg: '#004A4A',
  footerfg: 'white',
  footerbg: 'primary.darker',
  
  favicon: 'favicon.png',
  pageTitle: 'Delivery Tracker',

  logo: {
    src: 'logo.svg',
    width: '16rem',
    height: '4rem',
    link: 'https://www.roadie.com',
  },

  map: {
    markers: {
      start: 'start.svg',
      stop: 'stop.svg',
      end: 'end.svg',
      location: 'car.svg',
    }
  },

  faq: [
    {
      q: 'When will my delivery arrive?',
      a: 'The best way to get an estimate on delivery is by keeping an eye on the Delivery Tracker. You will also receive SMS updates on the driver’s status.',
    },
    {
      q: 'Do I have to be at the location for delivery?',
      a: 'No, unless a signature was requested. If you requested a signature and no one is present to provide one, your delivery will be returned and you will need to contact the sender to reschedule delivery. If you requested a signature but have changed your mind, please contact Roadie at <a href="tel:470-785-9787">(470) 785-9787</a>.',
    },
    {
      q: 'Can I change the location of my delivery?',
      a: 'If the driver has not picked up your delivery yet, contact Roadie at <a href="tel:470-785-9787">(470) 785-9787</a>. If the driver has already picked up your delivery, we are unable to change the location.',
    },
    {
      q: 'Can I hold my delivery until a later time?',
      a: 'If the driver has not picked up your delivery yet, contact the sender to reschedule. If the driver has already picked your delivery up, we are unable to delay delivery.',
    },
    {
      q: 'How is it being delivered?',
      a: 'With a delivery service called Roadie. When your delivery is scheduled, you will receive an email from Roadie with some information about delivery. Shortly afterwards, a driver picks up your delivery and you will receive another email and/or text message with a link to track the delivery. If you requested a signature upon delivery, either you or someone at the address will need to sign for the delivery when the driver arrives. If a signature was not requested, then the delivery will be left in place where it can be left unattended.',
    },
    {
      q: 'What is Roadie?',
      a: 'Roadie is an on-the-way delivery network that uses drivers heading in your direction to deliver items across town or across the country.',
    },
    {
      q: 'Where can I learn more about Roadie?',
      a: 'By visiting <a href="https://www.roadie.com" target="_blank">www.roadie.com</a>',
    },
  ]
}

export const roadieDark = {
  ...roadie,

  RoadieUI: RoadieUIDark,

  colors: dark,

  headerbg: '#0B8888',
}