import updateTree from './updateTree'

const _hack = el => {

  if (el.category === 'pickup_agent' && el.field_name === 'notes') {
    el.field_name = 'pickup_notes'
    el.category = 'pickup_notes'
  }

  if (el.field_name === 'delivery_deadline' && el.field_type === 'time_range') {
    el.field_name = 'delivery_window'
    el.category = 'delivery_window'
  }

  return el
}

const hack = els => {
  const updates = els.map(_hack)
  return updates
}

export default function cag(state = {}, action) {
  switch (action.type) {

    case 'SET_BATCH_LEAD_TIME': {
      return updateTree(state, 'cag.batch_lead_time_minutes', () => action.minutes)
    }

    case 'SET_CAG_MIN_DELIVERY_WINDOW': {
      return updateTree(state, 'cag.min_delivery_window', () => action.min)
    }

    case 'SET_CAG_TEMPLATE_ELEMENTS': {
      return updateTree(state, 'cag.template_elements', () => hack(action.template_elements))
    }

    case 'SET_CAG_ALLOWED_CERTS': {
      return updateTree(state, 'cag.allowed_certs', () => hack(action.certs))
    }

    default:
      return {
        cag: {
          template_elements: [],
          allowed_certs: [],
          min_delivery_window: 120,
          batch_lead_time_minutes: 0,
        },
        ...state
      }
  }
}