import updateTree from './updateTree'
import uniqueId from 'lodash/uniqueId'

/** Push a flash message to the stack */
function pushFlash(state, flash) {
  return updateTree(state, 'config.flashStack', flashStack => {
    const stack = flash.id ? flashStack.filter(f => f.id !== flash.id) : [...flashStack]
    if (!flash.id) {
      flash.id = uniqueId('flash-message-')
    }
    stack.push(flash)
    return stack
  })
}

/** Pop the current flash message from the stack */
function popFlash(state) {
  return updateTree(state, 'config.flashStack', flashStack => {
    const stack = [...flashStack]
    stack.pop()
    return stack
  })
}

/** Updates a flash by id or optionally pushes a new one */
function updateFlash(state, flash, create) {
  if (state.config.flashStack.find(f => f.id === flash.id)) {
    return updateTree(state, 'config.flashStack', flashStack => {
      const stack = [...flashStack]
      const index = stack.findIndex(f => f.id === flash.id)
      stack.splice(index, 1, flash)
      return stack
    })
  } else if (create) {
    return pushFlash(state, flash)
  } else {
    return state
  }
}

/** Removes a flash message by id */
function clearFlash(state, id) {
  return updateTree(state, 'config.flashStack', flashStack => {
    const stack = [...flashStack].filter(f => f.id !== id)
    return stack
  })
}

/** Removes all flash messages current and cache stack */
function clearAllFlash(state) {
  return updateTree(state, 'config.flashStack', () => [])
}

export default function config(state = {}, action) {
  switch (action.type) {

    case 'SET_APP_ROOT_PATH': {
      return updateTree(state, 'config.appRootPath', () => action.path)
    }

    case 'SET_SIDEBAR_OPEN': return updateTree(state, 'config.sidebarOpen', () => action.open)

    case 'SET_MEDIA_SIZE' : return updateTree(state, 'config.mediaSize', () => action.size)

    case 'SET_BADGES': return updateTree(state, 'config.badges', () => action.badges)

    case 'IS_WEBVIEW': return updateTree(state, 'config.isWebview', () => true)

    case 'WEBVIEW_REGISTRATION_WALKTHROUGH_COMPLETE': return updateTree(state, 'config.needsWebviewDriverWalkthroughComplete', () => true)

    case 'SET_TOAST': {
      return updateTree(state, 'config.toast', () => action.toast)
    }
    
    case 'PUSH_FLASH': {
      return pushFlash(state, action.flash)
    }

    case 'UPDATE_FLASH': {
      return updateFlash(state, action.flash, action.create)
    }

    case 'POP_FLASH': {
      return popFlash(state)
    }

    case 'CLEAR_FLASH': {
      return clearFlash(state, action.id)
    }

    case 'CLEAR_ALL_FLASH': {
      return clearAllFlash(state)
    }

    case 'MODAL_OPEN': return updateTree(state, 'config.modalOpen', () => true)
    case 'MODAL_CLOSE': return updateTree(state, 'config.modalOpen', () => false)

    case 'WAS_SIGNING_UP': return updateTree(state, 'config.wasSigningUp', () => true)

    case 'TOGGLE_CAG_HINTS': return updateTree(state, 'config.cagHints', cagHints => !cagHints)

    case 'CAN_INSTALL_PWA': return updateTree(state, 'config.canInstallPWA', () => true)

    default:
      return {
        config: {
          appRootPath: '/',
          sidebarOpen: false,
          isWebview: false,
          messagesSkipped: false,
          modalOpen: false,
          cagHints: true,
          badges: [],
          toast: {},
          flashStack: [],
        },
        ...state
      }
  }
}