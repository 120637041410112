import { css } from 'styled-components/macro'

// MEDIA QUERY MIXIN
//
// Usage:
//
// media.<device>`
//   declarations
// `
//
// Example:
//
// media.mobile`
//   font-size: 2em;
// `
//
// * desktop is anything above tablet max
// * mobile is anything below mobile max
// * tablet is everything between desktop and mobile


const TABLET_MAX = 900
const MOBILE_MAX = 600

export const sizes = {
  desktop: { min: TABLET_MAX+1 },
  tablet: { min: MOBILE_MAX+1, max: TABLET_MAX },
  mobile: { max: MOBILE_MAX },
}

function query(widths) {
  return Object.keys(widths).reduce((acc, label) => [
    ...acc, `(${label}-width: ${widths[label] / 16}em)`
  ], []).join(' AND ')
}

function _media(...labels) {
  return function(...args) {
    return css`
      ${labels.includes('desktop') && _media.desktop(...args)}
      ${labels.includes('tablet') && _media.tablet(...args)}
      ${labels.includes('mobile') && _media.mobile(...args)}
    `
  }
}

if (typeof Object.assign !== 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target, varArgs) { // .length of function is 2
      if (target === null || target === undefined) {
        throw new TypeError('Cannot convert undefined or null to object');
      }

      var to = Object(target);

      for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index];

        if (nextSource !== null && nextSource !== undefined) { 
          for (var nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}

export const media = Object.keys(sizes).reduce((acc, label) => Object.assign(acc, {
  [label]: (...args) => css`
    @media ${query(sizes[label])} {
      ${css(...args)}
    }
  `
}), _media)

export default media