import updateTree from './updateTree'
import keyBy from 'lodash/keyBy'
import uniq from 'lodash/uniq'
import without from 'lodash/without'

function mergeById(items, newItemsArr, complete) {
  const newItemsById = keyBy(newItemsArr, 'id')
  const newIds = newItemsArr.map(item => item.id)
  return {
    byId: Object.assign({}, items.byId || {}, newItemsById),
    ids: uniq([ ...(items.ids || []), ...newIds ]),
    complete,
  }
}

function removeById(id, items) {
  const byId = { ...items.byId }
  const item = byId[id]
  delete byId[id]
  return {
    item,
    items: {
      byId,
      ids: without(items.ids, Number(id)),
      complete: items.complete,
    }
  }
}

export default function gigs(state = {}, action) {
  switch (action.type) {

    case 'SET_MY_PICKUP_GROUPS': {
      return updateTree(state, 'gigs.mine.pickup_groups', () => action.pickup_groups)
    }

    case 'SET_MY_SENDING_CONSOLIDATIONS': {
      return updateTree(state, 'gigs.mine.sending_consolidations', () => action.data)
    }

    case 'SET_MY_SENDING_GIGS': {
      return updateTree(state, 'gigs.mine.sending', gigs => mergeById(gigs, action.gigs, action.complete))
    }

    case 'SET_MY_ARCHIVED_GIGS': {
      return updateTree(state, 'gigs.mine.archived', gigs => mergeById(gigs, action.gigs, action.complete))
    }

    case 'SET_MY_SEARCH_GIGS': {
      return updateTree(state, 'gigs.mine.search', gigs => mergeById(gigs, action.gigs, action.complete))
    }

    case 'CLEAR_MY_SEARCH_GIGS': {
      return updateTree(state, 'gigs.mine.search', () => ({}))
    }

    case 'SET_MY_SEARCH_GIGS_CRITERIA': {
      return updateTree(state, 'gigs.mine.criteria', () => action.criteria)
    }

    case 'CLEAR_MY_SEARCH_GIGS_CRITERIA': {
      return updateTree(state, 'gigs.mine.criteria', () => ({}))
    }

    case 'INVALIDATE_MY_SENDING_GIGS': {
      return updateTree(state, 'gigs.mine.sending', () => ({}))
    }

    case 'SET_GIG_TEMPLATE': {
      const stateWithTemplate = updateTree(state, 'gigs.template', () => action.template)
      return updateTree(stateWithTemplate, 'gigs.template_expiration', () => {
        const expires = new Date()
        expires.setMinutes(expires.getMinutes() + 10)
        return expires
      })
    }

    case 'REMOVE_GIG_FROM_LIST': {
      return updateTree(state, 'gigs.mine.sending', sending => removeById(action.id, sending))
    }

    case 'SET_GIG_DETAILS' : {
      return updateTree(state, `gigs.details.${action.gig.id}`, () => action.gig)
    }

    case 'GIG_CANCELED': {
      const { sending, archived } = state.gigs.mine
      const { item, items } = removeById(action.id, sending)
      
      if (item) {
        item.status = 'cancelled'
        const _archived = mergeById(archived, [item], archived.complete)
        const id = _archived.ids.pop()
        _archived.ids.unshift(id)
        return updateTree(state, 'gigs.mine', () => ({ archived: _archived, sending: items, search: {}, criteria: {} }))
      }

      return state
    }

    case 'GIG_RATED': {
      const { sending, archived } = state.gigs.mine
      const { item, items } = removeById(action.id, sending)

      if (item) {
        const _archived = mergeById(archived, [item], archived.complete)
        const id = _archived.ids.pop()
        _archived.ids.unshift(id)
        return updateTree(state, 'gigs.mine', () => ({ archived: _archived, sending: items }))
      }

      return state
    }

    default:
      return {
        gigs: {
          mine: {
            sending: {},
            archived: {},
            search: {},
            criteria: {},
          },
          details: {},
        },
        ...state
      }
  }
}