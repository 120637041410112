import updateTree from './updateTree'

export default function driving(state = {}, action) {
  switch (action.type) {

    case 'SET_DRIVING_SUMMARY': return updateTree(state, 'driving.summary', () => action.summary)

    case 'SET_DRIVING_HISTORY': return updateTree(state, `driving.history.${action.page}`, () => action.history)

    default:
      return {
        driving: {
        },
        ...state
      }
  }
}