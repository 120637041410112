import updateTree from './updateTree'

export default function identities(state = {}, action) {
  switch (action.type) {

    case 'SET_IDENTITIES_AVAILABLE': return updateTree(state, 'identities.available', () => action.identities)

    default:
      return {
        identities: {
          available: null
        },
        ...state
      }
  }
}