import updateTree from './updateTree'

export default function createGig(state = {}, action) {
  switch (action.type) {

    case 'ADD_CREATE_GIG_DATA': return updateTree(state, 'createGig', gigData => Object.assign({}, gigData, action.data))

    case 'CLEAR_CREATE_GIG_DATA': return updateTree(state, 'createGig', () => ({}))

    default:
      return {
        createGig: {
        },
        ...state
      }
  }
}