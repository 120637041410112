import updateTree from './updateTree'

export default function creditBalance(state = {}, action) {
  switch (action.type) {

    case 'SET_CREDITS': {
      return updateTree(state, 'creditBalance.credits', () => action.data.default)
    }

    default:
      return {
        creditBalance: {},
        ...state
      }
  }
}


