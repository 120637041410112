import updateTree from './updateTree'

export default function driving(state = {}, action) {
  switch (action.type) {

    case 'SET_TAX_SUMMARY': return updateTree(state, 'taxes', taxes => ({
      ...taxes,
      year: action.year,
      summary: action.summary,
      downloads: action.downloads,
    }))

    default:
      return {
        taxes: {
        },
        ...state
      }
  }
}