import updateTree from './updateTree'

export default function trainingCertifications(state = {}, action) {
  switch (action.type) {

    case 'SET_TRAINING_CERTS': {
      return updateTree(state, 'trainingCertifications', () => action.data)
    }

    default:
      return {
        trainingCertifications: {},
        ...state
      }
  }
}


