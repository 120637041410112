import { sizes } from 'styles/mixins/media'
import debounce from 'lodash/debounce'

function updateSize(store) {
  const width = window.innerWidth

  const size = Object.keys(sizes).find(key => {
    const { min, max } = sizes[key]
    let isMin = true, isMax = true

    if (min && min > width) {
      isMin = false
    }
    if (max && max < width) {
      isMax = false
    }
    
    return isMin && isMax
  })
  
  store.dispatch({ type: 'SET_MEDIA_SIZE', size })
}


export default function watch(store) {
  const handler =  debounce(() => updateSize(store), 200)
  window.addEventListener('resize', handler)
  updateSize(store)
}