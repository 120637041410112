import updateTree from './updateTree'

export default function profile(state = {}, action) {
  switch (action.type) {

    case 'SET_PROFILE': {
      return updateTree(state, 'profile', () => action.profile)
    }

    case 'SET_AUTHENTICITY_VERIFICATION': {
      return updateTree(state, 'authenticityVerificationState', () => action.state)
    }

    default:
      return {
        profile: null,
        authenticityVerificationState: null,
        ...state
      }
  }
}