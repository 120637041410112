import { FC, ReactNode } from 'react'
import { ThemeProvider } from 'styled-components/macro'
import { hooks } from 'roadie-ui'
import { roadie, roadieDark } from 'styles/themes'

type Theme = {
  [k: string]: any
}

const ColorScheme:FC<{light: Theme, dark:Theme, children:ReactNode}> = props => {
  const { light, dark } = props

  const themes = { light, dark }
  const mode = hooks.usePreferredColorScheme()

  const theme = themes[mode]

  return (
    <ThemeProvider theme={theme}>
      {props.children}
    </ThemeProvider>
  )
}

const RoadieThemeProvider:FC<{children:ReactNode}> = props => (
  <ColorScheme light={roadie} dark={roadieDark}>
    {props.children}
  </ColorScheme>
)

export default RoadieThemeProvider