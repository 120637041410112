import updateTree from './updateTree'
import pick from 'lodash/pick'

export default function mfa(state = {}, action) {
  switch (action.type) {

    case 'SET_MFA': return updateTree(state, 'mfa', () => pick(action, ['guid', 'method', 'sent_to', 'sent_on']))

    case 'CLEAR_MFA': return updateTree(state, 'mfa', () => ({ guid: null }))

    default:
      return {
        mfa: {
          guid: null,
        },
        ...state
      }
  }
}