import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from 'reducers'
import persistState from 'redux-sessionstorage'

function slicer () {
  return state => {
    const stateCopy = { ...state }
    delete stateCopy.config
    delete stateCopy.driving
    delete stateCopy.taxes
    delete stateCopy.gigs
    delete stateCopy.gigSearch
    delete stateCopy.cag
    delete stateCopy.createGig
    delete stateCopy.creditBalance
    delete stateCopy.messages
    delete stateCopy.profile
    delete stateCopy.identities
    return stateCopy
  }
}

const logger = store => next => action => {
  if (!action.type || 1) {
    let result = next(action)
    return result
  }
  console.group(action.type)
  console.info('dispatching', action)
  let result = next(action)
  console.log('next state', store.getState())
  console.groupEnd()
  return result
}

export default function configureStore(initialState={}, extra={}) {

  const enhancers = [applyMiddleware(logger, thunk.withExtraArgument(extra)), persistState(undefined, { slicer }) ]
  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__())
  }

  const store = createStore(
    rootReducer,
    initialState,
    compose(...enhancers)
  )

  window.store = store

  return store
}